import './globals.scss'
import 'normalize.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import '@blueprintjs/select/lib/css/blueprint-select.css'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import '@blueprintjs/datetime2/lib/css/blueprint-datetime2.css'

import { ApolloProvider } from '@apollo/client'
import { PagesProgressProvider as ProgressProvider } from '@bprogress/next'
import useScript from '@charlietango/use-script'
import { Colors } from '@curri/ui'
import { ErrorBoundary } from '@sentry/nextjs'
import { DeployRefreshManager } from 'components/DeployRefreshManager'
import ErrorBoundaryFallback from 'components/ErrorBoundaryFallback'
import { TrackingSetup } from 'components/TrackingSetup'
import { config } from 'config'
import { useGetCurrentUserQuery } from 'hooks-generated'
import dynamic from 'next/dynamic'
import Router from 'next/router'
import { CurrentUserProvider } from 'providers/CurrentUserProvider'
import React, { useEffect, useState } from 'react'
import { StripeProvider } from 'react-stripe-elements'
import styled from 'styled-components'
import { isProd } from 'utils/helpers'

import Head from '../components/head'
import { AdminMenu } from '../components/Menu'
import { CustomWindow } from '../interfaces'
import { client } from '../lib/graphql'

const DevRibbon = styled.div`
  background: #db3737;
  text-align: center;
  padding: 5px 0;
  position: fixed;
  z-index: 999;
  width: 170px;
  transform: rotate(-35deg);
  top: 20px;
  left: -40px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
  pointer-events: none;
  color: white;
`

const AppWrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  position: relative;

  button,
  .bp5-button,
  select,
  .bp5-input,
  input,
  .bp5,
  .bp5-card {
    border-radius: 2px;
    outline: none;
  }
`

const AppContainer = styled.div<{
  embedded?: string | string[] | undefined
}>`
  flex: 1;
  height: 100%;
  margin-left: ${({ embedded }) => (embedded ? '0px' : '66px')};
`

declare let window: CustomWindow

const App = ({ Component, pageProps }) => {
  const {
    data = {},
    refetch: refetchUser,
    loading,
    error,
  } = useGetCurrentUserQuery({ fetchPolicy: 'network-only' })
  const user = loading || error ? {} : data.currentUser
  const [safeToLoad, setSafeToLoad] = useState<boolean>(false)

  const { embedded } = Router.query

  useEffect(() => {
    ;(async () => {
      if (loading) {
        return
      }
      if (window.location.pathname.startsWith('/login')) {
        setSafeToLoad(true)
        return
      }

      if ((!user?.emailAddress || !user?.curriAdminSettings) && !error) {
        // return Router.push('/logout')
        // Router.push is not always working, not ideal
        // but this is our workaround for now

        window.localStorage.clear()
        window.location.href = '/login'

        return
      }

      setSafeToLoad(true)

      if (user?.emailAddress && window.location.pathname === '/') {
        return Router.push('/dashboard')
      }
    })()
  }, [data])

  return (
    <AppWrapper>
      <DeployRefreshManager />
      <TrackingSetup />
      <ProgressProvider
        color={Colors.TEAL_300}
        options={{ showSpinner: false }}
        height="4px"
      />

      <CurrentUserProvider>
        <Head title="Home" />
        <AdminMenu user={user} refetchUser={refetchUser} />

        <AppContainer embedded={embedded}>
          {safeToLoad && (
            <ErrorBoundary fallback={ErrorBoundaryFallback} showDialog>
              <Component
                {...pageProps}
                refetchUser={refetchUser}
                currentUser={user}
              />
            </ErrorBoundary>
          )}
        </AppContainer>
      </CurrentUserProvider>
    </AppWrapper>
  )
}

const NoSSRProviders = props => {
  const [ready] = useScript('https://js.stripe.com/v3/')
  const [stripe, setStripe] = useState(null)

  useEffect(() => {
    if (!ready) {
      return
    }
    if (stripe) {
      return
    }
    if (window.Stripe) {
      setStripe(window.Stripe(config.stripePublic.publicKey))
    }
  }, [ready])

  return (
    <ApolloProvider client={client}>
      <StripeProvider stripe={stripe}>
        <App {...props} />
      </StripeProvider>
    </ApolloProvider>
  )
}

const Providers = dynamic(async () => NoSSRProviders, { ssr: false })

function AppComponent(props: React.PropsWithChildren) {
  return (
    <>
      {!isProd && <DevRibbon>Development</DevRibbon>}
      <Providers {...props} />
    </>
  )
}

export default AppComponent
