import {
  Button,
  Card,
  Checkbox,
  Classes,
  Dialog,
  Divider,
  H4,
  Position,
  Switch,
  Tooltip,
} from '@blueprintjs/core'
import { useSetCurriAdminSettingsMutation } from 'hooks-generated'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Spacer } from '../Spacer'
import scss from './TogglePanel.module.scss'

const RemindersDialog = styled.div`
  padding: 15px;
`

type Reminder = {
  name: string
  description: string
}

const getReminders = ({ isActive, onBreak }) => {
  const clockInReminders: Reminder[] = [
    { description: "Stay sharp on SOP's!", name: 'SOP Refreshers' },
    { description: "Don't miss what's new!", name: 'Ops Deploys' },
    {
      description: 'Where does the team need you most right now!',
      name: 'Check in with Lead (Discord)',
    },
  ]

  const clockOutReminders: Reminder[] = [
    { description: 'Find this in Front!', name: 'Archive messages' },
    {
      description: 'Be sure you get Verbal confirmation from your team member',
      name: 'Add delivery notes and hand off deliveries',
    },
    {
      description: 'Post in Slack channel #driver-payout-needs',
      name: 'Report pay out issues',
    },
    {
      description: 'Be sure you write your CS reports 😜',
      name: 'Interdepartmental Communication',
    },
    {
      description: 'The better the driver, the better the delivery!',
      name: 'Driver Reviews complete',
    },
  ]

  let status

  if (onBreak) {
    status = onBreak
  } else if (!isActive) {
    status = !isActive
  } else if (isActive) {
    status = isActive
  }

  switch (status) {
    case !isActive:
      return clockOutReminders
    case isActive:
      return clockInReminders
  }
}

const Reminders = ({ isActive, onBreak }) => {
  return (
    <RemindersDialog>
      <table className="bp5-html-table .modifier">
        <thead>
          <tr>
            <th style={{ fontSize: '20px' }}>Reminders</th>
          </tr>
        </thead>
        <tbody>
          <div style={{ paddingTop: '3px' }}>
            {getReminders({ isActive, onBreak })?.map(reminder => {
              return (
                <tr>
                  <td>
                    <Tooltip
                      content={reminder.description}
                      position={Position.RIGHT_TOP}
                      openOnTargetFocus={false}
                    >
                      <Checkbox>
                        <span style={{ fontSize: '13px' }}>
                          {reminder.name}
                        </span>
                      </Checkbox>
                    </Tooltip>
                  </td>
                </tr>
              )
            })}
          </div>
        </tbody>
        <tfoot />
      </table>
    </RemindersDialog>
  )
}

export const TogglePanel = ({ user, refetchUser, refetchAdmins }) => {
  const [isDispatcherActive, setIsDispatcherActive] = useState<boolean>()
  const [isDispatcherOnBreak, setIsDispatcherOnBreak] = useState<boolean>()
  const [isAutoAssigning, setIsAutoAssigning] = useState<boolean>()
  const [dispatcherActiveAt, setDispatcherActiveAt] = useState<Date>()
  const [dispatcherOnBreakAt, setDispatcherOnBreakAt] = useState<Date>()
  const [showChecklistDialog, setShowChecklistDialog] = useState(false)

  const userExternalId: string = user?.externalId

  const [setCurriAdminSettings] = useSetCurriAdminSettingsMutation({
    variables: {
      settings: {
        ...user.curriAdminSettings,
        dispatcherActiveAt,
        dispatcherIsActive: isDispatcherActive,
        dispatcherIsAutoAssigning: isAutoAssigning,
        dispatcherOnBreak: isDispatcherOnBreak,
        dispatcherOnBreakAt,
      },
      userId: userExternalId,
    },
  })

  useEffect(() => {
    setIsDispatcherActive(!!user?.curriAdminSettings?.dispatcherIsActive)
    setIsDispatcherOnBreak(!!user?.curriAdminSettings?.dispatcherOnBreak)
    setDispatcherActiveAt(
      user?.curriAdminSettings?.dispatcherActiveAt ?? new Date()
    )
    setDispatcherOnBreakAt(
      user?.curriAdminSettings?.dispatcherOnBreakAt ?? new Date()
    )
    setIsAutoAssigning(!!user?.curriAdminSettings?.dispatcherIsAutoAssigning)
  }, [user?.curriAdminSettings])

  const handleDispatcherActive = async () => {
    await setIsDispatcherActive(!isDispatcherActive)
    await setDispatcherActiveAt(new Date())
    await setCurriAdminSettings()
    refetchUser()
    refetchAdmins()
  }

  const handleDispatcherOnBreak = async () => {
    await setIsDispatcherOnBreak(!isDispatcherOnBreak)
    await setDispatcherOnBreakAt(new Date())
    await setCurriAdminSettings()
    refetchUser()
    refetchAdmins()
  }

  const handleDispatcherAutoAssign = async () => {
    await setIsAutoAssigning(!isAutoAssigning)
    await setCurriAdminSettings()
    refetchUser()
    refetchAdmins()
  }

  const dispatcherTooltip = () => {
    if (isDispatcherActive) {
      if (isDispatcherOnBreak) {
        return 'Please come back from break first'
      } else {
        return ''
      }
    } else {
      return 'Please clock in first'
    }
  }

  return (
    <Card style={{ margin: '10px' }} elevation={2}>
      <H4>Personal Controls</H4>

      <Divider style={{ marginBottom: '10px' }} />

      <Switch
        disabled={isDispatcherOnBreak}
        checked={isDispatcherActive}
        onChange={async () => {
          if (isAutoAssigning) {
            await setIsAutoAssigning(!isAutoAssigning)
          }
          await handleDispatcherActive()
          setShowChecklistDialog(true)
        }}
        label={`Clock In/Out`}
      />

      <Spacer value={15} />

      <Tooltip content={isDispatcherActive ? '' : 'Please clock in first'}>
        <Switch
          disabled={!isDispatcherActive}
          checked={isDispatcherOnBreak}
          onChange={async () => {
            if (isAutoAssigning) {
              await setIsAutoAssigning(!isAutoAssigning)
            }
            await handleDispatcherOnBreak()
          }}
          label={`Take Break`}
        />
      </Tooltip>

      <Spacer value={15} />

      <Tooltip content={dispatcherTooltip()}>
        <Switch
          disabled={!isDispatcherActive || isDispatcherOnBreak}
          checked={isAutoAssigning}
          onChange={async () => {
            await handleDispatcherAutoAssign()
          }}
          label={`Auto-Assign Hotshots`}
        />
      </Tooltip>

      <Dialog
        className={scss.togglePanelReminder}
        portalClassName={scss.togglePanelReminder}
        usePortal={true}
        isOpen={showChecklistDialog}
        onClose={() => setShowChecklistDialog(false)}
        canOutsideClickClose={false}
      >
        <Reminders
          isActive={isDispatcherActive}
          onBreak={isDispatcherOnBreak}
        />
        <div className={Classes.DIALOG_FOOTER}>
          <Button
            outlined
            fill
            style={{ margin: 'auto', width: '50%' }}
            onClick={() => {
              setShowChecklistDialog(false)
            }}
          >
            Close
          </Button>
        </div>
      </Dialog>
    </Card>
  )
}

export default TogglePanel
