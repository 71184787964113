const getDomain = () => {
  const separate = window.location.host.split('.')
  separate.shift()
  return separate.join('.')
}
export const setCookie = (cname, cvalue, exMs) => {
  const d = new Date()
  d.setTime(d.getTime() + exMs)
  const expires = 'expires=' + d.toUTCString()
  const domain = 'domain=' + getDomain()
  document.cookie = `${cname}=${cvalue};${expires};${domain};path=/`
}
export const getCookie = cname => {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return undefined
}
