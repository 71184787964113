/* eslint-disable sort-keys-fix/sort-keys-fix */

type OptimizationOptions = {
  width?: number
  height?: number
  quality?: number
  format?: string
}

/**
 * Generate an optimized image URL using Cloudflare's Image Resizing service
 */
export function optimizeImageURL(
  src: string | null | undefined,
  options?: OptimizationOptions
) {
  if (!src) return ''
  if (localStorage.getItem('disable-image-optimization') === 'true') return src

  let { width, height, quality, format } = options ?? {}

  if (quality == null) {
    quality = 75
  }

  if (format == null) {
    format = 'webp'
  }

  const widthProperty = width ? `width=${width},` : ''
  const heightProperty = height ? `height=${height},` : ''

  return `https://images.curri.com/cdn-cgi/image/${widthProperty}${heightProperty}quality=${quality},format=${format}/${normalizeImageURL(
    src
  )}`
}

/**
 * Normalize an image URL by adding the missing protocol and removing leading and trailing slashes
 */
function normalizeImageURL(url: string) {
  // Remove leading and trailing slashes
  url = url.trim().replace(/^\/+|\/+$/g, '')

  // Add missing protocol
  if (!url.startsWith('http')) {
    url = `https://${url}`
  }

  return url
}
