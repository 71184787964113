import { Colors, Spinner } from '@blueprintjs/core'
import React, { MouseEventHandler } from 'react'
import styled, { css } from 'styled-components'

import { optimizeImageURL } from '../utils/optimization'

const Wrapper = styled.div<{ size?: number; showQuestionMark: boolean }>`
  height: ${({ size }) => `${size || 60}px`};
  width: ${({ size }) => `${size || 60}px`};
  border-radius: 50%;
  border: 2px solid ${Colors.LIGHT_GRAY1};
  position: relative;

  &:hover {
    transform: scale(1.1);
  }

  /* fill the container, preserving aspect ratio, and cropping to fit */
  background-size: cover;

  /* center the image vertically and horizontally */
  background-position: top center;

  ${({ showQuestionMark }) =>
    showQuestionMark &&
    css`
      &:before {
        content: '?';
        font-size: 16px;
        position: absolute;
        bottom: 0;
        top: 0;
        width: 100%;
        color: #999;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `}
`

export const RoundAvatar = React.forwardRef(
  (
    {
      src,
      size,
      onClick,
      style,
      spin,
      className,
      children,
    }: {
      src?: string | null
      size?: number
      onClick?: Function
      style?: React.CSSProperties
      spin?: boolean
      className?: string
      children?: React.ReactNode
    },
    _ref
  ) => {
    return spin ? (
      <Spinner size={size} />
    ) : (
      <Wrapper
        onClick={onClick as MouseEventHandler}
        size={size}
        showQuestionMark={!src}
        style={{
          backgroundImage: `url(${optimizeImageURL(src, {
            height: (size ?? 60) * 3,
            width: (size ?? 60) * 3,
          })})`,
          ...(style || {}),
        }}
        className={className}
      >
        {children}
      </Wrapper>
    )
  }
)
