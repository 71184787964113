import * as Sentry from '@sentry/nextjs'
import { useGetCurrentUserQuery, User } from 'hooks-generated'
import posthog from 'posthog-js'
import React, { useContext, useEffect } from 'react'
import { isProd } from 'utils/helpers'

const CurrentUserContext = React.createContext<{
  user?: User
  loading?: boolean
}>({})

const { Provider } = CurrentUserContext

export function CurrentUserProvider({
  children,
}: {
  children?: React.ReactNode
}) {
  const { data, loading } = useGetCurrentUserQuery()

  useEffect(() => {
    if (!data?.currentUser?.externalId) return

    const user = data?.currentUser

    if (user?.id && isProd) {
      // Prod PostHog Project
      posthog.init('phc_q3IHePQ4aTr2MEKiW8lEPSJskpvy7DZVTxEl9tnGS8', {
        advanced_disable_feature_flags_on_first_load: true,
        api_host: 'https://us.posthog.com',
      })
      posthog.identify(user?.externalId ?? undefined, {
        email: user?.emailAddress,
        name: user?.firstName + ' ' + user?.lastName,
      })

      // Sentry
      Sentry.setUser({ email: user?.emailAddress ?? undefined })
    }
  }, [data?.currentUser?.emailAddress])

  return (
    <Provider
      value={{
        loading,
        user: data?.currentUser ?? undefined,
      }}
    >
      {children}
    </Provider>
  )
}

export function useCurrentUserContext() {
  return useContext(CurrentUserContext)
}
