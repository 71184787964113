import { Card, H4, HTMLTable, Switch } from '@blueprintjs/core'
import { AdminPermissions } from '@curri/junk-drawer-client'
import { Spacer } from '@curri/ui'
import { DateUtils } from '@curri/utils'
import { useLocalStorage } from 'hooks/use-local-storage'
import {
  AdminOverviewAlertsQuery,
  AdminOverviewDeliveriesQuery,
  useAdminOverviewAlertsQuery,
  useAdminOverviewDeliveriesQuery,
  useCurriAdminsQuery,
  User,
  useSetCurriAdminSettingsMutation,
} from 'hooks-generated'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import styled from 'styled-components'
import { hasPermission, isDispatcher } from 'utils/helpers'

import { TogglePanel } from './TogglePanel'

const DISPATCHER_HEIGHT = 260
const DISPATCHER_WIDTH = 200
const DISPATCHER_BACKDROP_HEIGHT = 230
const DISPATCHER_BACKDROP_OFFSET = 30

const DrawerWrapper = styled.div`
  height: 800px;
  width: 1000px;
  overflow: scroll;
  padding: 25px;
`

const Dispatchers = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Avatar = styled.div`
  height: 50px;
  width: 50px;
  position: relative;
  left: 10px;
  border-radius: 15px;
  /* fill the container, preserving aspect ratio, and cropping to fit */
  background-size: cover;
  z-index: 1;

  /* center the image vertically and horizontally */
  background-position: top center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`

const DispatcherWrapper = styled.div`
  height: ${DISPATCHER_HEIGHT}px;
  width: ${DISPATCHER_WIDTH}px;
`

const DispatcherBackdrop = styled.div`
  position: relative;
  top: -${DISPATCHER_HEIGHT - DISPATCHER_BACKDROP_OFFSET}px;
  height: ${DISPATCHER_BACKDROP_HEIGHT}px;
  width: ${DISPATCHER_WIDTH}px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
`

const DispatcherForeground = styled.div`
  position: relative;
  height: ${DISPATCHER_HEIGHT}px;
  width: ${DISPATCHER_WIDTH}px;
  padding: 10px;
`

const DispatcherName = styled.div`
  margin-left: 10px;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  flex-wrap: wrap;
`

const DispatcherStatus = styled.div`
  margin-left: 10px;
  color: #afafaf;
  font-size: 12px;
`

const DispatcherDeliveries = styled.div`
  justify-content: center;
  margin: auto;
`

const StyledCard = styled(Card)`
  margin: 10px;
`

const RowLink = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  border-radius: 15px;
  text-decoration: none !important;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  border-radius: 15px;
`

const MetricWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50px;
`

const findNumberOfUniqueIds = (originalArray: unknown[]) => {
  const uniqueIdArray: unknown[] = []
  for (const id of originalArray) {
    if (!uniqueIdArray.includes(id)) {
      uniqueIdArray.push(id)
    }
  }
  return uniqueIdArray.length
}

const Metric = ({ name, number }: { name: string; number: number }) => {
  return (
    <MetricWrapper>
      <b style={{ color: '#6D6D6D', fontSize: 16 }}>{number}</b>
      <div style={{ color: '#AFAFAF', fontSize: 11 }}>{name}</div>
    </MetricWrapper>
  )
}

const Dispatcher = ({
  dispatcher,
  deliveriesData,
  deliveriesAlertsData,
  loadingDeliveries,
  loadingDeliveriesAlerts,
  user,
  showAutoAssignment,
  refetchAdmins,
}: {
  dispatcher
  deliveriesData: AdminOverviewDeliveriesQuery | undefined
  deliveriesAlertsData: AdminOverviewAlertsQuery | undefined
  loadingDeliveries
  loadingDeliveriesAlerts
  user: User
  showAutoAssignment: boolean
  refetchAdmins: Function
}) => {
  const router = useRouter()

  const isActive = !!dispatcher?.curriAdminSettings?.dispatcherIsActive
  const activeAt = dispatcher?.curriAdminSettings?.dispatcherActiveAt
  const isOnBreak = !!dispatcher?.curriAdminSettings?.dispatcherOnBreak
  const onBreakAt: Date = new Date(
    dispatcher?.curriAdminSettings?.dispatcherOnBreakAt
  )
  const isOnLunch = !!dispatcher?.curriAdminSettings?.dispatcherOnLunch
  const onLunchAt: Date = new Date(
    dispatcher?.curriAdminSettings?.dispatcherOnLunchAt
  )
  const dispatcherStatus = () => {
    // return
    if (isActive) {
      if (isOnBreak && !isOnLunch) {
        // display as on break
        return `On break since ${DateUtils.simpleDate(onBreakAt)}`
      } else if (isOnLunch && !isOnBreak) {
        // display as on lunch
        return `On lunch since ${DateUtils.simpleDate(onLunchAt)}`
      } else {
        // display as active
        return `Active since ${DateUtils.simpleDate(activeAt)}`
      }
    } else {
      // display as inactive
      return `Inactive since ${activeAt && DateUtils.simpleDate(activeAt)}`
    }
  }

  const deliveries = deliveriesData?.adminOverviewDeliveries?.filter(
    delivery =>
      dispatcher?.firstName === delivery?.dispatcher?.firstName &&
      dispatcher?.lastName === delivery?.dispatcher?.lastName
  )

  const findActiveDeliveries = (d: typeof deliveries) =>
    d?.filter(delivery => delivery?.deliveryStatus?.name !== 'Scheduled') ?? []

  const hotshotDeliveries = deliveries?.filter(
    delivery => !delivery?.routeInstance?.routeType
  )

  const routeDeliveries = deliveries?.filter(
    delivery => delivery?.routeInstance?.routeType === 'plan'
  )

  const msdDeliveries = deliveries?.filter(
    delivery => delivery?.routeInstance?.routeType === 'msd'
  )

  const numberOfRoutes = findNumberOfUniqueIds(
    (findActiveDeliveries(routeDeliveries) ?? []).map(
      obj => obj?.routeInstance?.id
    )
  )

  const numberOfActiveHotshots =
    findActiveDeliveries(hotshotDeliveries)?.length ?? 0

  const numberOfActiveMSDs = findNumberOfUniqueIds(
    findActiveDeliveries(msdDeliveries)?.map(obj => obj?.routeInstance?.id)
  )

  const numberOfDriverlessHotshotsAndMSDs =
    (findActiveDeliveries(hotshotDeliveries)?.filter(
      delivery => !delivery?.driver?.id
    ).length ?? 0) +
    (findNumberOfUniqueIds(
      findActiveDeliveries(msdDeliveries)
        ?.filter(delivery => !delivery?.driver?.id)
        ?.map(obj => obj?.routeInstance?.id)
    ) ?? 0)

  const numberOfScheduledHotshotsMSDsAndRoutes =
    (hotshotDeliveries?.filter(
      delivery => delivery?.deliveryStatus?.name === 'Scheduled'
    ).length ?? 0) +
    (findNumberOfUniqueIds(
      msdDeliveries
        ?.filter(delivery => delivery?.deliveryStatus?.name === 'Scheduled')
        ?.map(obj => obj?.routeInstance?.id) ?? []
    ) ?? 0) +
    (findNumberOfUniqueIds(
      routeDeliveries
        ?.filter(delivery => delivery?.deliveryStatus?.name === 'Scheduled')
        ?.map(obj => obj?.routeInstance?.id) ?? []
    ) ?? 0)

  const deliveriesWithAlerts = deliveriesAlertsData?.adminOverviewAlerts?.filter(
    delivery =>
      dispatcher?.firstName === delivery?.dispatcher?.firstName &&
      dispatcher?.lastName === delivery?.dispatcher?.lastName
  )

  const [, setFilterFacet] = useLocalStorage(
    'all_admin_deliveries_filter_facet',
    ''
  )

  const [setCurriAdminSettings] = useSetCurriAdminSettingsMutation()

  return (
    <div style={{ padding: 10 }}>
      <DispatcherWrapper>
        <DispatcherForeground>
          <Link passHref href={`/users/${dispatcher?.id}`} legacyBehavior>
            <Avatar
              style={{
                backgroundImage: `url(${dispatcher?.profileImageUrl})`,
              }}
            />
          </Link>
          {showAutoAssignment &&
            user?.id !== dispatcher?.id &&
            dispatcher?.curriAdminSettings?.dispatcherIsActive !== false && (
              <Switch
                style={{
                  position: 'absolute',
                  right: '5px',
                  top: '50px',
                  width: '110px',
                  zIndex: 1,
                }}
                onChange={async () => {
                  await setCurriAdminSettings({
                    variables: {
                      settings: {
                        ...dispatcher?.curriAdminSettings,
                        dispatcherIsAutoAssigning: !dispatcher
                          ?.curriAdminSettings.dispatcherIsAutoAssigning,
                      },
                      userId: dispatcher?.id,
                    },
                  })

                  refetchAdmins()
                }}
                label="Auto-Assign Dispatcher"
                checked={
                  !!dispatcher?.curriAdminSettings?.dispatcherIsAutoAssigning
                }
              />
            )}
          <div style={{ padding: 5 }} />

          <DispatcherName>
            {dispatcher?.firstName} {dispatcher?.lastName?.charAt(0)}.
          </DispatcherName>

          <DispatcherStatus style={{ color: isActive ? 'green' : '#afafaf' }}>
            {dispatcherStatus()}
          </DispatcherStatus>

          <div style={{ padding: 5 }} />

          <DispatcherDeliveries>
            {(!deliveries || loadingDeliveries) && (
              <div>Loading deliveries...</div>
            )}
            {deliveries && (
              <>
                <RowLink
                  style={{ backgroundColor: '#f2f2f2' }}
                  href={`/deliveries?status=active&dispatcher=${dispatcher?.emailAddress}`}
                >
                  <div />
                  <Metric name={'routes'} number={numberOfRoutes} />
                  <Metric name={'hotshots'} number={numberOfActiveHotshots} />
                  <Metric name={'msds'} number={numberOfActiveMSDs} />
                  <div />
                </RowLink>
                <Row>
                  <div />
                  <Metric
                    name={'driverless'}
                    number={numberOfDriverlessHotshotsAndMSDs}
                  />
                  <Metric
                    name={'scheduled'}
                    number={numberOfScheduledHotshotsMSDsAndRoutes}
                  />
                  <div />
                </Row>
                <div style={{ padding: 5 }} />
                {!deliveriesWithAlerts && loadingDeliveriesAlerts && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    Loading alerts...
                  </div>
                )}
                {deliveriesWithAlerts && !!deliveriesWithAlerts?.length && (
                  <div
                    style={{
                      color: 'red',
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                    }}
                    onClick={async () => {
                      await setFilterFacet(
                        `alerts_all,dispatcher_${dispatcher?.emailAddress}`
                      )
                      if (router.pathname === '/deliveries') {
                        router.reload()
                      } else {
                        router.push('/deliveries')
                      }
                    }}
                  >
                    {deliveriesWithAlerts?.length} deliveries throwing alerts
                  </div>
                )}
                {deliveriesWithAlerts && !deliveriesWithAlerts?.length && (
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                    }}
                  >
                    {isActive
                      ? `Running smoothly`
                      : `Resting in prep for next shift`}
                  </div>
                )}
              </>
            )}
          </DispatcherDeliveries>
        </DispatcherForeground>
        <DispatcherBackdrop />
      </DispatcherWrapper>
    </div>
  )
}

export const OverviewDrawer = ({ user, refetchUser }) => {
  const showAutoAssignment = hasPermission(
    user,
    AdminPermissions.overrideDispatcherAutoAssignment
  )

  const {
    data: curriAdminsData,
    loading: loadingAdmins,
    refetch: refetchAdmins,
  } = useCurriAdminsQuery({
    fetchPolicy: 'no-cache',
  })
  const curriAdmins = curriAdminsData?.curriAdmins
  const curriDispatchers = curriAdmins?.filter(admin => isDispatcher(admin))

  // get current admin to display in employee dashboard
  const currentDispatcher = curriDispatchers?.find(
    dispatcher => dispatcher?.id === user.id
  )

  const onlineDispatcherEmails = curriAdmins
    ?.filter(
      admin =>
        admin?.curriAdminSettings?.dispatcherIsActive &&
        admin.emailAddress !== user?.emailAddress
    )
    .map(dispatcher => dispatcher?.emailAddress ?? null)

  const {
    data: deliveriesData,
    loading: loadingDeliveries,
  } = useAdminOverviewDeliveriesQuery({
    variables: {
      dispatcherEmails:
        curriDispatchers?.map(dispatcher => dispatcher?.emailAddress ?? null) ??
        [],
    },
  })

  const {
    data: deliveriesAlertsData,
    loading: loadingDeliveriesAlerts,
  } = useAdminOverviewAlertsQuery({
    variables: {
      dispatcherEmails: onlineDispatcherEmails,
    },
  })

  const assignmentSort = (dispatcher1, dispatcher2) => {
    const d1IsAutoAssigning =
      dispatcher1?.curriAdminSettings?.dispatcherIsAutoAssigning
    const d1IsActive = dispatcher1?.curriAdminSettings?.dispatcherIsActive

    const d2IsAutoAssigning =
      dispatcher2?.curriAdminSettings?.dispatcherIsAutoAssigning
    const d2IsActive = dispatcher2?.curriAdminSettings?.dispatcherIsActive

    if (d1IsAutoAssigning) {
      return -1
    }
    if (d2IsAutoAssigning) {
      return 1
    }
    if (d1IsActive) {
      return -1
    }
    if (d2IsActive) {
      return 1
    }
    return 0
  }

  const countSort = (dispatcher1, dispatcher2) => {
    const d1Count = dispatcher1?.dispatcherDeliveryCount
    const d2Count = dispatcher2?.dispatcherDeliveryCount

    if (d1Count > d2Count) {
      return 1
    }
    if (d1Count < d2Count) {
      return -1
    }
    return 0
  }

  const dispatchersInAssignmentQueue = curriDispatchers?.filter(
    admin => admin?.curriAdminSettings?.dispatcherIsAutoAssigning
  )

  const numberOfHotshots = deliveriesData?.adminOverviewDeliveries?.filter(
    delivery => !delivery?.routeInstance?.routeType
  ).length

  const msdIDs =
    deliveriesData?.adminOverviewDeliveries
      ?.filter(delivery => delivery?.routeInstance?.routeType === 'msd')
      .map(obj => obj?.routeInstance?.id) ?? []

  const routesIDs =
    deliveriesData?.adminOverviewDeliveries
      ?.filter(delivery => delivery?.routeInstance?.routeType === 'plan')
      .map(obj => obj?.routeInstance?.id) ?? []

  return (
    <DrawerWrapper>
      {curriDispatchers && (
        <>
          <div style={{ display: 'flex' }}>
            <StyledCard elevation={2}>
              <HTMLTable interactive compact>
                <H4>Stats</H4>
                <tr>
                  <td>Active Dispatchers</td>
                  <td>
                    {
                      curriDispatchers?.filter(
                        admin => admin?.curriAdminSettings?.dispatcherIsActive
                      ).length
                    }
                  </td>
                </tr>
                <tr>
                  <td>Inactive Dispatchers</td>
                  <td>
                    {curriDispatchers.length -
                      curriDispatchers?.filter(
                        admin => admin?.curriAdminSettings?.dispatcherIsActive
                      ).length}
                  </td>
                </tr>
                <tr>
                  <td>Hotshots</td>
                  <td>{numberOfHotshots}</td>
                </tr>
                <tr>
                  <td>MSDs</td>
                  <td>{findNumberOfUniqueIds(msdIDs)}</td>
                </tr>
                <tr>
                  <td>Dedicated Routes</td>
                  <td>{findNumberOfUniqueIds(routesIDs)}</td>
                </tr>
              </HTMLTable>
            </StyledCard>

            {showAutoAssignment && (
              <StyledCard elevation={2}>
                <HTMLTable interactive compact>
                  <thead>
                    <H4>
                      Auto Assigning Dispatchers:{' '}
                      {dispatchersInAssignmentQueue?.length}
                    </H4>
                    <Spacer value={2} />
                    <tr>
                      <th>Dispatcher</th>

                      <th># of Active Hotshots</th>
                    </tr>
                  </thead>

                  <tbody>
                    {dispatchersInAssignmentQueue?.sort(countSort) &&
                      dispatchersInAssignmentQueue.map(dispatcher => {
                        return (
                          <tr>
                            <td>
                              {dispatcher?.firstName} {dispatcher?.lastName}
                            </td>

                            <td>{dispatcher?.dispatcherDeliveryCount}</td>
                          </tr>
                        )
                      })}
                  </tbody>
                </HTMLTable>
              </StyledCard>
            )}
          </div>

          <div style={{ display: 'flex' }}>
            <div style={{ float: 'left' }}>
              {currentDispatcher && (
                <Dispatcher
                  key={currentDispatcher?.id}
                  dispatcher={currentDispatcher}
                  deliveriesData={deliveriesData}
                  deliveriesAlertsData={deliveriesAlertsData}
                  loadingDeliveries={loadingDeliveries}
                  loadingDeliveriesAlerts={loadingDeliveriesAlerts}
                  user={user}
                  showAutoAssignment={showAutoAssignment}
                  refetchAdmins={refetchAdmins}
                />
              )}
            </div>

            <div style={{ paddingTop: '30px' }}>
              {isDispatcher(user) && (
                <TogglePanel
                  user={user}
                  refetchUser={refetchUser}
                  refetchAdmins={refetchAdmins}
                />
              )}
            </div>
          </div>
        </>
      )}
      <Dispatchers>
        {(loadingAdmins || loadingDeliveries || loadingDeliveriesAlerts) && (
          <div style={{ padding: 10 }}>Loading admins..</div>
        )}
        {curriDispatchers &&
          deliveriesData &&
          curriDispatchers
            ?.filter(dispatcher => dispatcher !== currentDispatcher)
            .sort(assignmentSort)
            .map(dispatcher => {
              return (
                <Dispatcher
                  key={dispatcher?.id}
                  dispatcher={dispatcher}
                  deliveriesData={deliveriesData}
                  deliveriesAlertsData={deliveriesAlertsData}
                  loadingDeliveries={loadingDeliveries}
                  loadingDeliveriesAlerts={loadingDeliveriesAlerts}
                  user={user}
                  showAutoAssignment={showAutoAssignment}
                  refetchAdmins={refetchAdmins}
                />
              )
            })}
      </Dispatchers>
    </DrawerWrapper>
  )
}

export default OverviewDrawer
