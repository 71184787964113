import { Button, EditableText, HTMLTable } from '@blueprintjs/core'
import styled from 'styled-components'

// Define styled components
const Table = styled(HTMLTable)`
  width: 100%;
`

const DialogBody = styled.div`
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
`

const VehicleTag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  padding: 3px 5px;
  border-radius: 3px;
  border: solid 1px rgba(0, 168, 255, 1);
  background: rgba(0, 168, 255, 0.1);
  color: rgba(0, 151, 230, 1);
  margin-right: 3px;
  margin-bottom: 3px;
`

const DropdownRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  &:hover {
    background: rgba(0, 168, 255, 0.1);
  }
`

const DropdownInput = styled.div`
  flex-flow: wrap;
  padding: 5px;
  border: solid 1px rgba(24, 32, 38, 0.25);
  border-radius: 3px;
  position: relative;
  z-index: 3;
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const DropdownShadow = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 30px;
  border-radius: 3px;
  box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgb(19 124 189 / 30%),
    inset 0 1px 1px rgb(16 22 26 / 20%);
  z-index: 1;
`

const DropdownContainer = styled.div`
  position: relative;
  cursor: text;
`

const DropdownSuggest = styled.ul`
  position: absolute;
  top: 27px;
  box-sizing: border-box;
  margin: 0;
  width: 100%;
  border: solid 1px rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  background: #fff;
  z-index: 5;
  list-style: none;
  padding: 4px 0 0 0;
`

const DropdownItem = styled.li`
  margin: 0;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
  width: 100%;
`

const ReuploadContainer = styled.div`
  width: 295px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: #fff;
  border-radius: 12px;
`
const ReuploadChild = styled.label`
  display: flex;
  border-radius: 8px;
  border: solid 1px rgba(0, 0, 0, 0.25);
  padding: 32px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;
  width: 100%;
  cursor: pointer;
  svg {
    color: #0094de;
    width: 32px;
    height: auto;
  }
  &:hover {
    background-color: rgb(249, 250, 251);
  }
  p.large {
    opacity: 1;
    font-size: 16px;
    margin-top: 8px;
    color: #000;
    font-weight: 500;
    margin-bottom: 4px;
  }
  p.small {
    opacity: 0.7;
    font-size: 14px;
    color: #000;
    padding: 0;
    margin: 0;
  }
`
const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`
const DrawerSectionHeader = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const InfoPanel = styled.div`
  display: flex;
  padding: 32px;
  flex-direction: column;
  background: #f0f0f0;
  border-bottom: solid 1px rgba(0, 0, 0, 0.08);
`
const InfoDetail = styled.p`
  font-size: 15px;
`
const MaxRow = styled.div`
  font-size: 15px;
  width: auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 4px;
`
const Bold = styled.span`
  font-size: inherit;
  font-weight: 700;
  margin-right: 3px;
`
const DrawerChild = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
`
const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  justify-content: center;
  align-items: center;
  border: solid 1px rgba(0, 0, 0, 0.15);
  background: rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  margin-bottom: 32px;
  margin-top: 8px;
  font-size: 13px;
  font-weight: 600;
  overflow: hidden;
`
const PaymentPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border: solid 1px rgba(0, 0, 0, 0.15);
  background: rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  margin-bottom: 32px;
  margin-top: 8px;
  font-size: 13px;
  font-weight: 600;
  overflow: hidden;
`
const PlaceholderChild = styled.div`
  font-weight: 600;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  text-align: center;
`
const PopoverGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 220px;
`
const PopoverLabel = styled.label`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 4px;
`
const PopoverInput = styled.input`
  height: 32px;
  padding: 6px;
  border-radius: 3px;
  border: solid 1px rgba(0, 0, 0, 0.2);
  font-size: 12px;
  margin-bottom: 8px;
`
const PopoverSelect = styled.select`
  height: 32px;
  padding: 6px;
  border-radius: 3px;
  border: solid 1px rgba(0, 0, 0, 0.2);
  font-size: 12px;
  margin-bottom: 8px;
`
const PopoverSelectBigger = styled.select`
  height: 60px;
  padding: 6px;
  border-radius: 3px;
  border: solid 1px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  margin-bottom: 8px;
`
const DriverLink = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
  align-items: center;
  text-decoration: none !important;
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
`
const VehicleLink = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  width: 100%;
  border-radius: 4px;
  align-items: center;
  text-decoration: none !important;
`
const MoreButton = styled.div`
  display: flex;
  flex-direction: row;
  width: 32px;
  height: 32px;
  margin-left: 8px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
  cursor: pointer;
  background: transparent;
  &:hover {
    background-color: rgba(255, 255, 255, 0.85);
  }
`
const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.1);
  margin-right: 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`
const DriverName = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
`
const VehicleName = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
`
const VehicleDetails = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: auto;
  margin-bottom: 8px;
`
const VehicleImage = styled.img`
  width: 100%;
  height: auto;
  margin-right: 10px;
`
const VehicleImageLarge = styled.img`
  width: 100%;
`
const DriverPermissions = styled.div`
  font-size: 12px;
  font-weight: 500;
  border-radius: 100px;
  max-height: 35px;
  background-color: #8dffde;
  padding: 4px 10px;
  text-transform: capitalize;
  color: #000;
`
const VehicleNotes = styled.div`
  font-size: 12px;
  font-weight: 500;
  padding: 4px 10px;
  color: #fb1900;
`
const DriverLinkChild = styled.a`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  text-decoration: none !important;
  &:hover {
    color: #0094de;
  }
`
const DriverEmail = styled.div`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
  margin-top: 4px;
`
const DriverData = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
`
const VehicleData = styled.div`
  display: flex;
  flex-direction: row;
  align-content: space-between;
  align-items: center;
`
const VehicleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  &:hover {
    background: rgba(0, 168, 255, 0.1);
  }
`
const ButtonColumn = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  a {
    margin-bottom: 4px;
    width: 100%;
  }
`
const PaymentGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
  align-items: flex-start;
  label {
    margin-bottom: 6px;
    font-size: 12px;
    font-weight: 500;
  }
  input {
    font-size: 12px;
    padding: 6px;
    border-radius: 4px;
    border: solid 1px rgba(0, 0, 0, 0.2);
    width: 100vw;
    max-width: 220px;
  }
`

const NotesWrapper = styled.div`
  position: relative;
  margin-bottom: 24px;
`

const NotesBox = styled(EditableText)`
  border: 1px solid #eee;
  padding: 5px;
  margin-top: 8px;
`

const NotesSubmit = styled(Button)`
  position: absolute;
  bottom: 0;
  right: 0;
`

const Group = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 6px 3px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 8px;
  }
`

const DenialReason = styled.li`
  margin-bottom: 6px;
  font-weight: 500;
  font-size: 15px;
  color: red;
`

const FakeLink = styled.div`
  margin: 8px auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 13px;
  padding: 4px 16px;
  cursor: pointer;
  color: red;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
`

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  label {
    margin-bottom: 4px;
    font-weight: 500;
    font-size: 14px;
  }
  select {
    width: 100%;
    height: 24px;
    display: flex;
    font-size: 12px;
    padding: 0 6px;
    border-radius: 3px;
  }
`

export {
  Table,
  DialogBody,
  VehicleTag,
  DropdownRow,
  DropdownInput,
  DropdownShadow,
  DropdownContainer,
  DropdownSuggest,
  DropdownItem,
  ReuploadContainer,
  ReuploadChild,
  DrawerContainer,
  DrawerSectionHeader,
  InfoPanel,
  InfoDetail,
  MaxRow,
  Bold,
  DrawerChild,
  Placeholder,
  PaymentPlaceholder,
  PlaceholderChild,
  PopoverGroup,
  PopoverLabel,
  PopoverInput,
  PopoverSelect,
  PopoverSelectBigger,
  DriverLink,
  VehicleLink,
  MoreButton,
  Avatar,
  DriverName,
  DriverPermissions,
  DriverLinkChild,
  DriverEmail,
  DriverData,
  ButtonColumn,
  PaymentGroup,
  VehicleNotes,
  NotesWrapper,
  NotesBox,
  NotesSubmit,
  Group,
  DenialReason,
  FakeLink,
  InputGroup,
  VehicleDetails,
  VehicleImage,
  VehicleData,
  VehicleName,
  VehicleImageLarge,
  VehicleContainer,
}
