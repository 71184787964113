import {
  Icon,
  Menu,
  MenuItem,
  Popover,
  PopoverPosition,
  Position,
  Switch,
  Tooltip,
} from '@blueprintjs/core'
import { AdminPermissions } from '@curri/junk-drawer-client'
import { LegacyColors } from '@curri/ui'
import { RoundAvatar } from 'components/RoundAvatar'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { baseUrl, isDispatcher } from 'utils/helpers'

import { Spacer } from '../Spacer'
import { OverviewDrawer } from './OverviewDrawer'

export const NAV_WIDTH = 65

const Wrapper = styled.div`
  height: 100%;
  background: ${LegacyColors.DARK_GRAY_1};
  width: ${NAV_WIDTH}px;
  flex-shrink: 0;
  padding-top: 75px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: -100%;
  padding-bottom: 5000px;

  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  z-index: 25;
  position: fixed;
  top: 0;
  bottom: 0;
`

const MenuIcon = styled.a<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  height: 45px;
  width: 45px;
  margin-top: 5px;
  outline: none;

  .bp5-popover-wrapper {
    line-height: 0;
  }

  img {
    height: 20px;
    line-height: 0;
    display: block;

    &:focus-visible {
      border: none;
      outline: none;
    }

    ${({ isActive }) => {
      return { filter: isActive ? 'brightness(0)' : 'brightness(1);' }
    }}
  }

  img.drivers {
    height: 18px;
  }

  ${({ isActive }) => {
    return {
      background: isActive ? LegacyColors.CURRI_GREEN_VIBRANT : 'transparent',
    }
  }}

  ${({ isActive }) => {
    return (
      !isActive &&
      css`
        &:hover {
          background: ${LegacyColors.DARK_GRAY_2};
        }
      `
    )
  }}
`

const SubMenuItem = ({ label, href }: { label: string; href: string }) => {
  return (
    <Link passHref href={href} legacyBehavior>
      <MenuItem
        style={{ outline: 'none' }}
        className="popover-dismiss"
        href={href}
        text={label}
      />
    </Link>
  )
}

type SubMenuTarget =
  | 'itineraries'
  | 'drivers'
  | 'routes'
  | 'tools'
  | 'users'
  | 'settings'

const SubMenu = ({
  target,
  token = '',
}: {
  target: SubMenuTarget
  token?: string
}) => {
  switch (target) {
    case 'itineraries':
      return (
        <Menu>
          <SubMenuItem href="/itineraries" label="Itineraries" />
          <SubMenuItem
            href="/itineraries/subscriptions"
            label="Itinerary Subscriptions"
          />
        </Menu>
      )

    case 'drivers':
      return (
        <Menu>
          <SubMenuItem label="Carrier Search" href="/drivers/carriers" />
          <SubMenuItem label="Gig Driver Search" href="/drivers" />
          <SubMenuItem
            label="Delivery Service Provider Search"
            href="/drivers/delivery-service-providers"
          />
          <SubMenuItem label="Checkr" href="/drivers/checkr-tools/search" />
          <MenuItem
            style={{ outline: 'none' }}
            className="popover-dismiss"
            onClick={async () => {
              const deliver = window.open(
                `${baseUrl.deliver}/supply-signup/step-1`,
                '_blank'
              )
              // wait 1 second and then post the jwt to the new window
              if (deliver && token) {
                await new Promise(resolve => setTimeout(resolve, 1000))
                deliver.postMessage(
                  { jwtToken: token },
                  `${baseUrl.deliver}/supply-signup/step-1`
                )
              }
            }}
            text={'Create New'}
          />
        </Menu>
      )

    case 'routes':
      return (
        <Menu>
          <SubMenuItem label="Contract Hours" href="/routes/contract-hours" />
          <SubMenuItem
            label="Driver Pay Rates"
            href="/routes/driver-pay-rates"
          />
          <SubMenuItem label="DVIRs" href="/routes/dvir" />
          <SubMenuItem label="MSDs" href="/routes?routeType=msd" />
          <SubMenuItem label="Quotes" href="/routes/quotes" />
          <SubMenuItem label="Routes" href="/routes" />
          <SubMenuItem label="Templates" href="/routes/templates" />
          <SubMenuItem label="Vehicles" href="/routes/vehicles" />
        </Menu>
      )

    case 'tools':
      return (
        <Menu>
          <SubMenuItem
            label="Address Correction"
            href={`/${target}/addresses`}
          />
          <SubMenuItem
            label="Compliance Payouts"
            href={`/${target}/compliance`}
          />
          <SubMenuItem
            label="Create Invoices"
            href={`/${target}/create-invoice`}
          />
          <SubMenuItem
            label="Discount Templates"
            href={`/${target}/discount-templates`}
          />
          <SubMenuItem
            label="DispatchTrack Routes Uploader"
            href={`/${target}/dispatchtrack-routes-uploader`}
          />
          <SubMenuItem
            label="Fulfillment Payouts"
            href={`/${target}/fulfillment-payouts`}
          />
          <SubMenuItem
            label="Carrier Approvals"
            href={`/${target}/carrier-approvals`}
          />
          <SubMenuItem label="Invite New Booker" href={`/${target}/invite`} />
          <SubMenuItem label="Invoices" href={`/${target}/invoices`} />
          <SubMenuItem
            label="LTL Carrier Requirements"
            href={`/${target}/ltl-carrier-requirements`}
          />
          <SubMenuItem
            label="Payment Batches"
            href={`/${target}/payment-batches`}
          />
          <SubMenuItem
            label="Pricing Constants"
            href={`/${target}/pricing-constants`}
          />
          <SubMenuItem
            label="Pricing Engines"
            href={`/${target}/pricing-engines`}
          />
          <SubMenuItem
            label="Pricing Fee Rules"
            href={`/${target}/pricing-fee-rules`}
          />
          <SubMenuItem
            label="Send Mass Drivers SMS"
            href={`/${target}/send-mass-drivers-sms`}
          />
          <SubMenuItem
            label="Simple Quote Tool"
            href={`/${target}/simple-quote-tool`}
          />
        </Menu>
      )

    case 'users':
      return (
        <Menu>
          <SubMenuItem label="User Search" href={`/${target}`} />
          <SubMenuItem label="Teams" href={`/${target}/teams`} />
        </Menu>
      )

    case 'settings':
      return (
        <Menu>
          <SubMenuItem label={'Admin Settings'} href={`/${target}`} />
          <SubMenuItem
            label={'Exceptions Editor'}
            href={`/exceptions/editor`}
          />
        </Menu>
      )

    default:
      return null
  }
}

export const AdminMenu = ({ user, refetchUser }) => {
  const router = useRouter()
  const { embedded } = router.query

  const [isDispatcherActive, setIsDispatcherActive] = useState<boolean>()

  const jwt = localStorage.getItem('jwt')

  useEffect(() => {
    if (user?.curriAdminSettings?.dispatcherIsActive) {
      setIsDispatcherActive(true)
    } else {
      setIsDispatcherActive(false)
    }
  }, [user?.curriAdminSettings])

  if (embedded) return null

  const itinerariesAccess = user?.userAdminPermissions?.find(
    permission =>
      permission?.adminPermission?.name === AdminPermissions.itineraries
  )

  return (
    <>
      <Wrapper>
        <Tooltip content="Go to my profile" position={PopoverPosition.RIGHT}>
          <Link passHref href={`/users/${user.externalId}`} legacyBehavior>
            <MenuIcon>
              <RoundAvatar
                src={user.profileImageUrl}
                size={30}
                style={{ cursor: 'pointer', margin: '0 auto' }}
              />
            </MenuIcon>
          </Link>
        </Tooltip>

        <Spacer value={15} />

        {isDispatcher(user) && (
          <Tooltip
            content={
              isDispatcherActive ? 'You are clocked in' : 'You are clocked out'
            }
          >
            <Switch
              disabled
              style={{ marginLeft: '8px' }}
              checked={isDispatcherActive}
            />
          </Tooltip>
        )}

        <Spacer value={15} />

        <Popover
          popoverClassName="bp5-popover-content"
          content={<OverviewDrawer user={user} refetchUser={refetchUser} />}
        >
          <Tooltip content="Ops Dashboard" position={Position.RIGHT}>
            <MenuIcon>
              <img
                style={{ outline: 'none' }}
                src={require('./icons/capacity.svg')}
              />
            </MenuIcon>
          </Tooltip>
        </Popover>

        <Tooltip content="Dashboard" position={Position.RIGHT}>
          <Link
            passHref
            href={{
              pathname: '/dashboard',
            }}
            legacyBehavior
          >
            <MenuIcon
              isActive={router.pathname?.toLowerCase() === '/dashboard'}
            >
              <img
                style={{ outline: 'none' }}
                src={require('./icons/deliveries.svg')}
              />
            </MenuIcon>
          </Link>
        </Tooltip>

        <Tooltip
          content="Search Historical Deliveries"
          position={Position.RIGHT}
        >
          <Link
            passHref
            href={{
              pathname: '/search',
            }}
            legacyBehavior
          >
            <MenuIcon isActive={router.pathname?.includes('/search')}>
              <img
                style={{ outline: 'none' }}
                src={require('./icons/search.svg')}
              />
            </MenuIcon>
          </Link>
        </Tooltip>

        <Popover
          popoverClassName="bp5-dark"
          content={<SubMenu target={'routes'} />}
        >
          <Tooltip content="Routes" position={Position.RIGHT}>
            <MenuIcon isActive={router.pathname?.startsWith('/routes')}>
              <img
                style={{ outline: 'none' }}
                src={require('./icons/routes.svg')}
              />
            </MenuIcon>
          </Tooltip>
        </Popover>

        {itinerariesAccess && (
          <Popover
            popoverClassName="bp5-dark"
            content={<SubMenu target={'itineraries'} />}
          >
            <Tooltip content="Itineraries" position={Position.RIGHT}>
              <MenuIcon isActive={router.pathname?.startsWith('/itineraries')}>
                <Icon
                  icon="route"
                  color={
                    router.pathname?.startsWith('/itineraries')
                      ? 'black'
                      : 'white'
                  }
                />
              </MenuIcon>
            </Tooltip>
          </Popover>
        )}

        <Popover
          popoverClassName="bp5-dark"
          content={<SubMenu target={'users'} />}
        >
          <Tooltip content="Users" position={Position.RIGHT}>
            <MenuIcon isActive={router.pathname?.startsWith('/users')}>
              <img
                style={{ outline: 'none' }}
                src={require('./icons/customers.svg')}
              />
            </MenuIcon>
          </Tooltip>
        </Popover>

        <Popover
          popoverClassName="bp5-dark"
          content={<SubMenu target={'drivers'} token={jwt || undefined} />}
        >
          <Tooltip content="Drivers" position={Position.RIGHT}>
            <MenuIcon isActive={router.pathname?.startsWith('/drivers')}>
              <img
                style={{ outline: 'none' }}
                src={require('./icons/drivers.svg')}
                className="drivers"
              />
            </MenuIcon>
          </Tooltip>
        </Popover>

        <Tooltip content="Orders" position={Position.RIGHT}>
          <Link passHref href="/orders" legacyBehavior>
            <MenuIcon isActive={router.pathname?.startsWith('/orders')}>
              <img
                style={{ outline: 'none' }}
                src={require('./icons/orders.svg')}
              />
            </MenuIcon>
          </Link>
        </Tooltip>

        <Popover
          popoverClassName="bp5-dark"
          content={<SubMenu target={'tools'} />}
        >
          <Tooltip content="Tools" position={Position.RIGHT}>
            <MenuIcon isActive={router.pathname?.startsWith('/tools')}>
              <img
                style={{ outline: 'none' }}
                src={require('./icons/tools.svg')}
              />
            </MenuIcon>
          </Tooltip>
        </Popover>

        <Popover
          popoverClassName="bp5-dark"
          content={<SubMenu target={'settings'} />}
        >
          <Tooltip content="Settings" position={Position.RIGHT}>
            <MenuIcon
              isActive={
                router.pathname?.includes('/settings') ||
                router.pathname?.includes('/exceptions/editor')
              }
            >
              <Icon
                style={{
                  color:
                    router.pathname?.includes('/settings') ||
                    router.pathname?.includes('/exceptions/editor')
                      ? 'black'
                      : 'white',
                  outline: 'none',
                }}
                icon="cog"
                size={24}
              />
            </MenuIcon>
          </Tooltip>
        </Popover>

        <Tooltip content="Logout" position={Position.RIGHT}>
          <Link passHref href="/logout" legacyBehavior>
            <MenuIcon>
              <img
                style={{ outline: 'none' }}
                src={require('./icons/logout.svg')}
                className="logout"
              />
            </MenuIcon>
          </Link>
        </Tooltip>
      </Wrapper>
    </>
  )
}
