import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { OverlayToaster, Position, Toaster } from '@blueprintjs/core'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { baseUrl } from 'utils/helpers'

const ErrorToaster: Promise<Toaster> | null =
  typeof document === 'object'
    ? OverlayToaster.createAsync(
        {
          position: Position.BOTTOM_RIGHT,
        },
        {
          domRenderer: (toaster, containerElement) =>
            createRoot(containerElement).render(toaster),
        }
      )
    : null

const BackendErrors: string[] = []
const NetworkErrors: string[] = []

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (operation.getContext().errorHandled) {
    return
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      if (BackendErrors.includes(message)) {
        return
      }

      BackendErrors.push(message)
      ErrorToaster &&
        ErrorToaster?.then(toaster =>
          toaster.show({
            icon: 'error',
            intent: 'danger',
            message: (
              <>
                <strong>BACKEND ERROR:</strong> {message}
              </>
            ),
            onDismiss: () => {
              BackendErrors.splice(BackendErrors.indexOf(message), 1)
            },
          })
        )
    })
  }

  if (networkError) {
    const { message } = networkError
    if (NetworkErrors.includes(message)) return
    NetworkErrors.push(message)
    ErrorToaster &&
      ErrorToaster.then(toaster =>
        toaster.show({
          icon: 'error',
          intent: 'danger',
          message: (
            <>
              <strong>NETWORK ERROR:</strong> {message}
            </>
          ),
          onDismiss: () => {
            NetworkErrors.splice(NetworkErrors.indexOf(message), 1)
          },
        })
      )
  }
})

const authLink = setContext(async (operation, prevContext) => {
  const jwt = localStorage.getItem('jwt')

  return {
    headers: {
      ...prevContext.headers,
      'accept': 'application/json',
      'authorization':
        operation?.operationName === 'tryApiKeysAsCurrentUser'
          ? ''
          : `Bearer ${jwt}`,
      'x-curri-app-version': process.env.APP_VERSION,
      'x-graphql-operation': operation.operationName,
    },
  }
})

const customFetch = (uri, options) => {
  const { operationName } = JSON.parse(options.body)
  return fetch(`${uri}?operation=${operationName}`, options)
}

const httpLink = new HttpLink({
  credentials: 'include', // Additional fetch() options like `credentials` or `headers`
  fetch: customFetch,
  uri: `${baseUrl.api}/graphql`,
})

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  connectToDevTools: false,
  defaultOptions: {
    mutate: {
      errorPolicy: 'all',
    },
    query: {
      errorPolicy: 'all',
    },
    watchQuery: {
      errorPolicy: 'all',
    },
  },
  link: ApolloLink.from([authLink, errorLink, httpLink]),
})
