import { getCookie, setCookie } from './cookies'

const CURRI_SESSION_ID_COOKIE_NAME = 'curri_session_id'

const setSessionId = (sessionId = new Date().getTime() + '') => {
  const expireTime = 30 * 60 * 1000 // 30 minutes from now
  setCookie(CURRI_SESSION_ID_COOKIE_NAME, sessionId, expireTime)
  return sessionId
}

export const getSessionId = () => {
  if (typeof window === 'undefined') return ''

  const storedSessionId = getCookie(CURRI_SESSION_ID_COOKIE_NAME)
  if (storedSessionId) {
    setSessionId(storedSessionId)
    return storedSessionId
  } else {
    return setSessionId()
  }
}
