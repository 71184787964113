import { Button, Dialog } from '@blueprintjs/core'
import { Spacer } from '@curri/ui'
import useInterval from '@use-it/interval'
import axios from 'axios'
import { DialogBody } from 'components/CarrierStyles'
import React, { useState } from 'react'

export const DeployRefreshManager = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useInterval(async () => {
    const { buildId } = (await axios.get('/api/build-id')).data
    if (buildId && process.env.BUILD_ID && buildId !== process.env.BUILD_ID) {
      // There's a new version deployed that we need to load
      setIsOpen(true)
    }
  }, 30 * 1000)

  return (
    <Dialog
      style={{ width: '350px' }}
      isOpen={isOpen}
      title="New Version Available"
      isCloseButtonShown={false}
    >
      <DialogBody style={{ display: 'block' }}>
        <div>Please refresh the page to get the latest changes.</div>
        <Spacer value={5} />
        <Button
          outlined
          large
          style={{ float: 'left', width: '150px' }}
          intent="danger"
          onClick={() => {
            setIsOpen(false)
          }}
        >
          Dismiss
        </Button>
        <Button
          outlined
          large
          style={{ float: 'right', width: '150px' }}
          intent="primary"
          onClick={() => window.location.reload()}
        >
          Refresh
        </Button>
      </DialogBody>
    </Dialog>
  )
}
