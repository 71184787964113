import styled from 'styled-components'

interface ISpacer {
  value: number
}

export const Spacer = styled.div`
  height: ${({ value }: ISpacer) => `${value + 1}px`};
  margin-top: -1px;
`
