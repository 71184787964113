import NextHead from 'next/head'
import React, { useEffect, useState } from 'react'
import { isProd } from 'utils/helpers'

const defaultDescription = "Curri's Admin Interface"
const defaultOGURL = ''
const defaultOGImage = ''

const Head = props => {
  const [fontSize, setFontSize] = useState('13px')
  const [fontWeight, setFontWeight] = useState('400')
  useEffect(() => {
    const localFontSize = window.localStorage.getItem('admin:fontSize')
    const localFontWeight = window.localStorage.getItem('admin:fontWeight')

    if (localFontSize) setFontSize(localFontSize)
    if (localFontWeight) setFontWeight(localFontWeight)
  }, [])

  return (
    <NextHead>
      <meta charSet="UTF-8" />
      <meta
        name="description"
        content={props.description || defaultDescription}
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="mask-icon" href="/static/favicon-mask.svg" color="#49B882" />
      <link rel="icon" href={isProd ? '/favicon.png' : '/favicon-dev.png'} />

      <meta
        property="og:description"
        content={props.description || defaultDescription}
      />
      <meta name="twitter:site" content={props.url || defaultOGURL} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={props.ogImage || defaultOGImage} />
      <meta property="og:image" content={props.ogImage || defaultOGImage} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />

      <style
        dangerouslySetInnerHTML={{
          __html: `

          * { box-sizing: border-box }

          * {
            font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI',
              'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
              'Segoe UI Symbol';
            font-weight: ${fontWeight};
            font-size: ${fontSize};
          }

          body {
            max-width: 100vw;
          }

          b, strong {
            font-weight: bold;
          }

          input, textarea {
            font-size: 16px;
          }
        `,
        }}
      />
    </NextHead>
  )
}

export default Head
