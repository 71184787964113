import { Callout, Section, SectionCard } from '@blueprintjs/core'
import React from 'react'
import styled from 'styled-components'

const StyledSection = styled(Section)`
  margin: 20px;
  max-width: 90vw;
`

const ErrorBoundaryFallback = ({ error, componentStack }) => {
  return (
    <React.Fragment>
      <Callout intent="danger" icon="error" title="A wild bug appeared!">
        We've logged this error, but please submit a report in{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://teamcurri.slack.com/archives/C055D8XDD0D"
        >
          #bugs
        </a>{' '}
        so we can work on fixing this ASAP.
      </Callout>

      <StyledSection icon="error" title="Error">
        <SectionCard>{error.toString()}</SectionCard>
      </StyledSection>

      <StyledSection
        icon="stacked-chart"
        title="Component Stack"
        collapsible
        collapseProps={{ defaultIsOpen: false }}
      >
        <SectionCard>{componentStack}</SectionCard>
      </StyledSection>
    </React.Fragment>
  )
}

export default ErrorBoundaryFallback
