import { isProd } from 'utils/helpers'

export const config = {
  mapboxAccessToken:
    'pk.eyJ1IjoiYnJpYW5jdXJyaSIsImEiOiJja3NpMmZiYWwyMGp4MzFxdDdkeDhrYTBzIn0.3rWn9gBUvMLyhO8xxcZM7Q',
  stripePublic: {
    publicKey: isProd
      ? 'pk_live_KlqRoDVgLXVgu9K2vLcayWAy'
      : 'pk_test_fjqvCgJaE5QCiR1Y6QjMkie6',
  },
}
