import { AdminPermissions } from '@curri/junk-drawer-client'
import { User } from 'hooks-generated'

export const isDefined = <T>(value: T): value is NonNullable<T> =>
  typeof value !== 'undefined' && value !== null

export const sumReducer = (total, current) => total + current

export const msToInterval = (ms: number) => {
  // const seconds = Math.floor((ms / 1000) % 60) // s
  const minutes = Math.floor((ms / (1000 * 60)) % 60) // min
  // const hours = Math.floor((ms / (1000 * 60 * 60)) % 24) // hr
  const hours = Math.floor(ms / (1000 * 60 * 60)) // hr
  // const days = Math.floor(ms / (1000 * 60 * 60 * 24)) // d
  // const secStr = seconds ? seconds.toFixed(0) + 's' : ''
  const minStr = minutes ? minutes.toFixed(0) + 'm ' : ''
  const hrStr = hours ? hours.toFixed(0) + 'hr ' : ''
  // const dayStr = days ? days.toFixed(0) + 'd ' : ''
  return hrStr + minStr
}

export const formatNumber = (
  value: number | string,
  fractionDigits = 0,
  options: Intl.NumberFormatOptions = {}
): string =>
  new Intl.NumberFormat('en-US', {
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
    ...options,
  }).format(Number(value))

export const formatMoney = (
  value: number | string,
  fractionDigits = 0,
  options: Intl.NumberFormatOptions = {}
): string =>
  formatNumber(value, fractionDigits, {
    currency: 'USD',
    style: 'currency',
    ...options,
  })

/**
 * Checks if a passed in role matches any of the roles on a user
 * @param user The user to check roles on
 * @param permissionName
 * @returns Whether any of the passed in roles match any of the user's roles
 */
export const hasPermission = (
  user: User | undefined | null,
  permissionName: string
): boolean => {
  return (
    user?.userAdminPermissions?.some(
      permission => permission?.adminPermission?.name === permissionName
    ) ?? false
  )
}

export const isDispatcher = (user: User | undefined | null): boolean => {
  return (
    hasPermission(user, AdminPermissions.hotshotDispatcher) ||
    hasPermission(user, AdminPermissions.freightDispatcher)
  )
}

export const canMakeDriverPayout = (user: User | undefined | null): boolean => {
  return (
    hasPermission(user, AdminPermissions.payDriverMax1k) ||
    hasPermission(user, AdminPermissions.payDriverMax2k) ||
    hasPermission(user, AdminPermissions.payDriverNoMax)
  )
}

export const isProd = process.env.NODE_ENV === 'production'

const tld = isProd ? 'com' : 'dev'
export const baseUrl = {
  admin: `https://admin.curri.${tld}`,
  api: `https://api.curri.${tld}`,
  app: `https://app.curri.${tld}`,
  deliver: `https://deliver.curri.${tld}`,
  docs: `https://docs.curri.${tld}`,
  logos: 'https://logos.curri.com',
  metabase: 'https://metabase.curri.com',
  streamlit: 'https://streamlit.curri.com',
}

export const itemPredicate = (
  query: string,
  item: string | { name: string }
): boolean => {
  if (!query) {
    return true
  }

  if (typeof item === 'string') {
    return query
      .split(' ')
      .some(chunk => item.toLowerCase().includes(chunk.toLowerCase()))
  } else {
    return query
      .split(' ')
      .some(chunk => item.name.includes(chunk.toLowerCase()))
  }
}

/**
 * Simple Fuzzy Text Search
 *
 * Checks whether the `textHaystack` string has all the characters contained in
 * the `needle` in the same order.
 *
 * @example
 *   fuzzyTextMatch('h o w d', 'Hello World') // true
 *   fuzzyTextMatch('world hello', 'Hello World') // false
 */
export const fuzzyTextMatch = (needle: string, textHaystack: string) => {
  const needleRegex = new RegExp(
    `${needle
      .replace(/\s+/g, '') // Remove white space
      .toLowerCase()
      .split('')
      .map(character => character.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')) // Escape special chars
      .join('.*')}` // Insert `.*` RegEx pattern between each character
  )

  return needleRegex.test(textHaystack.toLowerCase())
}
