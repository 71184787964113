import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { getSessionId } from '../utils/session-id'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any

const options = {
  Intercom: { hideDefaultLauncher: true },
  integrations: {
    Amplitude: {
      session_id: getSessionId(), //eslint-disable-line
    },
  },
}

const trackPageView = () => {
  if ('analytics' in window && window.analytics.page) {
    window.analytics.page(
      {
        href: window.location.href,
        origin: window.location.origin,
        pathname: window.location.pathname,
      },
      options
    )
  }
}

const trackEvent = (eventName: string) => {
  if ('analytics' in window && window.analytics.page) {
    window.analytics.track(eventName, options)
  }
}

export const TrackingSetup = () => {
  const router = useRouter()

  useEffect(() => {
    router.events.on('routeChangeComplete', () => {
      trackPageView()
    })
  }, [])

  // Track the first page view.
  useEffect(() => {
    trackPageView()
  }, [])

  useEffect(() => {
    if (typeof window === 'undefined') return
    document.querySelectorAll('body')[0].addEventListener(
      'click',
      () => {
        trackEvent('Page Clicked')
      },
      true // "true" tells addEventListener to use capture mode instead of bubble mode
    )
  }, [])

  useEffect(() => {
    if (typeof window === 'undefined') return
    document.addEventListener(
      'visibilitychange',
      () => {
        if (document.hidden) trackEvent('Browser Window/Tab Backgrounded')
      },
      false
    )
  }, [])

  return null
}
